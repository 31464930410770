function initBarcode(objects) {
	objects.forEach((i) => {
		JsBarcode(i.id, i.code, i.options)
	})
}

function printHtml(title,html_body)
{
	let print_window= window.open('', 'PRINT', 'height=800,width=600');
	print_window.addEventListener('afterprint', function() {
		print_window.close();
	});

	let html = `<!Doctype html>
	<html>
		<head>
			<title>${title}</title>
		</head>
		<body>${html_body}</body>
	</html>`;

	print_window.document.open();
	print_window.document.write(html);
	print_window.document.close();
	print_window.focus(); // necessary for IE >= 10*/
	print_window.print();
	setTimeout(function() {
		print_window.close();
	},500);
}

function txt2html(txt)
{
	if( !txt )
		return '';

	var s = document.createElement('span');
	s.textContent = txt;
	return s.innerHTML.replace(/\n/g,'<br>');
}
