function renderPaypalButton2(rest,order_id, paypal_button_selector, goToPaypalSuccessCallback  )
{
	try
	{
		paypal.Buttons
		({
			createOrder: function (data, actions)
			{
				console.log("Que pasa aqui", data, "Creando orden",order_id );
				return rest.createPaypalOrder(order_id)
			},
			onApprove: (data, actions)=>
			{
				console.log('On aprove regresa', data );
				return rest.captureOrder( data.orderID ).then(()=>
				{
					console.log('Order Captured');
					goToPaypalSuccessCallback();
					return Promise.resolve(true);
				});
			}
			,onCancel: (data, actions)=>
			{
				console.log("ON Cancel message", data, actions );
				rest.showError('el pago fue cancelado');
			}
			,onError: (error)=>
			{
				if( error.error && error.error.error )
				{
					rest.showError( error.error.error );
				}
				console.log('paypal An error occurred',error);
				rest.showError('Ocurrio un error al procesar el pago, por favor intente de nuevo');
			}
		}).render( paypal_button_selector ); //selector example #paypal_button_id
	}
	catch(error)
	{
		console.log("Ocurrio un error desconocido",error);
	}
}
